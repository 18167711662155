<template>
  <div class="flex-centent mobile-login">
    <a-spin :spinning="spinning">
      <div class="login" v-if="!spinning">
        <div v-if="islogin">
          <a-form
            class="user-layout-login"
            :model="formState"
            @finish="handleFinish"
          >
            <div class="logo">
              <img src="@/assets/logo2.png" />
              <span class="title">威智造</span>
            </div>
            <div
              class="remark flex islogin-remark"
              :title="
                invitation.invitationPsn +
                '邀请你加入' +
                invitation.invitationEnterprise
              "
            >
              <div>
                <span class="invitationPsn">{{
                  invitation.invitationPsn
                }}</span>
              </div>
              <div class="invitation_enterprise">
                邀请你加入{{ invitation.invitationEnterprise }}
              </div>
            </div>
            <a-form-item :wrapper-col="{ span: 24 }">
              <a-button
                type="primary"
                size="large"
                class="islogin-button"
                :loading="state.loginBtn"
                @click.stop.prevent="Acception"
              >
                立即加入
              </a-button>
            </a-form-item>
          </a-form>
        </div>
        <div v-else-if="failed" class="lose-efficacy">
          <a-form class="user-layout-login" :model="formState">
            <div class="logo">
              <img src="@/assets/logo2.png" />
              <span class="title">威智造</span>
            </div>
            <div class="remark failed-remark" :title="invitation.remark">
              {{
                invitation.remark == undefined
                  ? "链接已失效"
                  : invitation.remark
              }}
            </div>
            <div class="failedInfo">
              {{ errorMessage }}
            </div>
            <a-button
              type="primary"
              size="large"
              block
              v-if="buttonVisible"
              html-type="submit"
              class="login-button"
              :loading="state.loginBtn"
              @click="backLogin"
            >
              {{ failedBtn }}
            </a-button>
          </a-form>
        </div>
        <div v-else-if="linkView">
          <a-form
            :rules="rules"
            class="user-layout-login"
            :model="formState"
            @finish="handleFinish"
          >
            <div class="logo">
              <img src="@/assets/logo2.png" />
              <span class="title">威智造</span>
            </div>
            <div
              class="remark"
              :title="
                invitation.invitationPsn +
                '邀请你加入' +
                invitation.invitationEnterprise
              "
            >
              <div>
                <span class="invitationPsn">{{
                  invitation.invitationPsn
                }}</span>
              </div>
              <div class="invitation_enterprise">
                邀请你加入{{ invitation.invitationEnterprise }}
              </div>
            </div>
            <a-form-item required name="name" :wrapper-col="{ span: 24 }">
              <a-input
                size="large"
                type="text"
                placeholder="姓名"
                v-if="!state.registToLogin"
                v-model:value="formState.name"
                :maxlength="11"
              >
                <template #prefix>
                  <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item required name="phone" :wrapper-col="{ span: 24 }">
              <a-input
                size="large"
                type="text"
                placeholder="手机号"
                v-model:value="formState.phone"
                :maxlength="11"
              >
                <template #prefix>
                  <MobileOutlined style="color: rgba(0, 0, 0, 0.25)" />
                </template>
              </a-input>
            </a-form-item>
            <a-row :gutter="16">
              <a-col class="gutter-row" :span="16">
                <a-form-item
                  required
                  name="checkCode"
                  :wrapper-col="{ span: 24 }"
                >
                  <a-input
                    size="large"
                    type="text"
                    placeholder="验证码"
                    v-model:value="formState.checkCode"
                    :maxlength="6"
                  >
                    <template #prefix>
                      <MailOutlined style="color: rgba(0, 0, 0, 0.25)" />
                    </template>
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col class="gutter-row" :span="8">
                <a-button
                  class="getCaptcha"
                  v-if="startCountDown"
                  @click.stop.prevent="getCaptcha"
                >
                  获取验证码
                </a-button>
                <div class="countDownTimer flex-centent" v-if="!startCountDown">
                  {{ countDownNum }} 秒后重发
                </div>
              </a-col>
            </a-row>
            <a-form-item required name="password" :wrapper-col="{ span: 24 }">
              <a-input-password
                v-if="!state.registToLogin"
                v-model:value="formState.password"
                type="password"
                size="large"
                placeholder="登录密码"
                :maxlength="18"
              >
                <template #prefix>
                  <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
                </template>
              </a-input-password>
            </a-form-item>

            <!-- <a-form-item :wrapper-col="{ span: 24 }">
              <a-checkbox v-model:checked="protocolChecked">
                <font color="#C0C0C0">我已阅读并且同意</font>
              </a-checkbox>
              <a @click="protocolClick">《威智造服务协议书》</a>
            </a-form-item> -->

            <a-form-item style="margin-top: 24px" :wrapper-col="{ span: 24 }">
              <a-button
                v-if="!state.registToLogin"
                type="primary"
                html-type="submit"
                size="large"
                class="islogin-button"
                :loading="state.loginBtn"
              >
                注册
              </a-button>
            </a-form-item>

            <a-form-item style="margin-top: 24px" :wrapper-col="{ span: 24 }">
              <a-button
                v-if="state.registToLogin"
                type="primary"
                html-type="submit"
                size="large"
                class="islogin-button"
                :loading="state.loginBtn"
              >
                登录
              </a-button>
            </a-form-item>

            <a-form-item
              v-if="!state.registToLogin"
              :wrapper-col="{ span: 24 }"
            >
              <font color="#C0C0C0">已有账号？</font>
              <a @click="backLogin">返回登录</a>
            </a-form-item>
            <!--<a-form-item
              v-if="false"
              style="margin-top: 52px"
              :wrapper-col="{ span: 24 }"
            >
              <a-button
                type="primary"
                html-type="submit"
                size="large"
                class="login-button"
                :loading="state.loginBtn"
              >
                加入
              </a-button>
            </a-form-item> -->
          </a-form>
        </div>
        <div v-else-if="phoneView">
          <a-form
            :rules="rules"
            class="user-layout-login"
            :model="formState"
            @finish="handleFinish"
          >
            <div class="logo">
              <img src="@/assets/logo2.png" />
              <span class="title">威智造</span>
            </div>
            <div
              class="remark flex"
              :title="
                invitation.invitationPsn +
                '邀请你加入' +
                invitation.invitationEnterprise
              "
            >
              <div>
                <span class="invitationPsn">{{
                  invitation.invitationPsn
                }}</span>
              </div>
              <div class="invitation_enterprise">
                邀请你加入{{ invitation.invitationEnterprise }}
              </div>
            </div>
            <a-form-item required name="name" :wrapper-col="{ span: 24 }">
              <a-input
                size="large"
                type="text"
                placeholder="姓名"
                v-if="!state.registToLogin"
                v-model:value="formState.name"
                :maxlength="11"
              >
                <template #prefix>
                  <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
                </template>
              </a-input>
            </a-form-item>
            <a-form-item required name="phone" :wrapper-col="{ span: 24 }">
              <a-input
                size="large"
                type="text"
                placeholder="手机号"
                disabled
                v-model:value="formState.phone"
                :maxlength="11"
              ></a-input>
            </a-form-item>
            <a-form-item required name="password" :wrapper-col="{ span: 24 }">
              <a-input-password
                v-model:value="formState.password"
                v-if="!state.registToLogin"
                type="password"
                size="large"
                placeholder="登录密码"
                :maxlength="18"
              >
                <template #prefix>
                  <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
                </template>
              </a-input-password>
            </a-form-item>
            <a-row :gutter="16">
              <a-col class="gutter-row" :span="16">
                <a-form-item
                  required
                  name="checkCode"
                  :wrapper-col="{ span: 24 }"
                >
                  <a-input
                    size="large"
                    type="text"
                    placeholder="验证码"
                    v-model:value="formState.checkCode"
                    :maxlength="6"
                  >
                    <template #prefix>
                      <MailOutlined style="color: rgba(0, 0, 0, 0.25)" />
                    </template>
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col class="gutter-row" :span="8">
                <a-button
                  class="getCaptcha"
                  v-if="startCountDown"
                  @click.stop.prevent="getCaptcha"
                >
                  获取验证码
                </a-button>
                <div class="countDownTimer flex-centent" v-if="!startCountDown">
                  {{ countDownNum }} 秒后重发
                </div>
              </a-col>
            </a-row>
            <!-- <a-form-item :wrapper-col="{ span: 24 }">
              <a-checkbox v-model:checked="protocolChecked">
                <font color="#C0C0C0">我已阅读并且同意</font>
              </a-checkbox>
              <a @click="protocolClick">《威智造服务协议书》</a>
            </a-form-item> -->

            <a-form-item style="margin-top: 24px" :wrapper-col="{ span: 24 }">
              <a-button
                v-if="!state.registToLogin"
                type="primary"
                html-type="submit"
                size="large"
                class="islogin-button"
                :loading="state.loginBtn"
              >
                注册并加入
              </a-button>
            </a-form-item>

            <a-form-item style="margin-top: 24px" :wrapper-col="{ span: 24 }">
              <a-button
                v-if="state.registToLogin"
                type="primary"
                html-type="submit"
                size="large"
                class="islogin-button"
                :loading="state.loginBtn"
              >
                登录
              </a-button>
            </a-form-item>

            <!-- <a-form-item :wrapper-col="{ span: 24 }">
              <font color="#C0C0C0">已有账号？</font>
              <a @click="backLogin">返回登录</a>
            </a-form-item>
             <a-form-item
              v-if="false"
              style="margin-top: 52px"
              :wrapper-col="{ span: 24 }"
            >
              <a-button
                type="primary"
                html-type="submit"
                size="large"
                class="login-button"
                :loading="state.loginBtn"
              >
                加入
              </a-button>
            </a-form-item> -->
          </a-form>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { LockOutlined, MobileOutlined, MailOutlined, UserOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { getProfileDetail } from '@/api/IdentityAPI'
import { verifyLogin } from '@/api/account/AccountAPI'
// import ProtocolView from '@/components/ProtocolView'
import api from '@/api/API'
// import { Decrypt, Encrypt } from '@/utils/secret.js'
// const userId = '39fc90eb-91d4-2d9c-7efc-f6313d0d1488'

export default defineComponent({
  name: 'account-InviteMobile',
  components: {
    UserOutlined,
    LockOutlined,
    MobileOutlined,
    MailOutlined,
    // ProtocolView,
  },
  data () {
    return {
      spinning: false, // 是否加载
      invitation: {
        shortKey: '',
        invitationEnterprise: null,
        invitationPsn: null,
        contactWay: null,
        valid: null,
        remark: null,
        result: true,
        temporaryId: null,
      },
      buttonVisible: true,
      protocolChecked: false,
      phoneView: false,
      emailView: false,
      linkView: false,
      failed: false,
      failedBtn: '',
      rules: {
        name: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur',
          },
        ],

        phone: [
          {
            required: true,
            message: '请输入手机号码',
            trigger: 'blur',
          },
          {
            len: 11,
            message: '请输入正确的11位手机号码',
            trigger: 'blur',
          },
        ],

        password: [
          {
            required: true,
            message: '请输入正确6-16位包含数字,大小写字母,非字符串',
            trigger: 'blur',
          },
        ],

        checkCode: [
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur',
          },
          {
            len: 6,
            message: '请输入正确的6位数字验证码',
            trigger: 'blur',
          },
        ],
      }, // 验证规则
      formState: {
        name: '',
        phone: '',
        password: '',
        checkCode: '',
        inviteKey: '',
        invitationWay: '',
        inviteWayStr: '',
      },
      state: {
        time: 60,
        loginBtn: false,
        loginType: 0,
        smsSendBtn: false,
        registToLogin: false,
      },
      islogin: false,
      protocolVisible: false,
      startCountDown: true,
      countDownNum: '', // 倒计时
      countDownTimer: '',
      errorMessage: '邀请已取消或者过期，可通过注册联系邀请人',
      data: {},
    }
  },
  created () {
    this.invitationVerification()
  },
  methods: {
    // 页面加载时验证
    invitationVerification () {
      this.spinning = true
      const { token, tenant } = this.$store.state.account
      const query = this.$route.query
      api
        .post('/api/app/employee-invitation/invitation-link-verification', {
          shortKey: query.shortKey,
        })
        .then(({ data }) => {
          this.spinning = false
          this.invitation = data
          // console.log(this.invitation)
          this.invitation.shortKey = query.shortKey
          this.formState.inviteKey = query.inviteKey
          this.formState.invitationWay = data.invitationWay
          switch (this.formState.invitationWay) {
            case 0: {
              this.formState.inviteWayStr = '链接'
              this.linkView = true
              break
            }
            case 1: {
              this.formState.inviteWayStr = '手机号码'
              this.phoneView = true
              this.formState.phone = data.contactWay
              break
            }
            case 2: {
              this.formState.inviteWayStr = '邮箱链接'
              this.linkView = true
              break
            }
          }
          switch (this.invitation.result) {
            // 如果邀请结果验证不成功，显示失效提示
            case 0: {
              if (token.access_token !== undefined) {
                this.islogin = true
              } else {
                this.islogin = false
              }
              this.failed = false
              break
            }
            case 1: {
              this.islogin = false
              this.failed = true
              this.buttonVisible = false
              break
            }
            case 2: {
              this.islogin = false
              this.failed = true
              this.errorMessage = '当前登录账号已加入该企业'
              if (!window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
                this.failedBtn = '点击进入'
              } else {
                this.buttonVisible = false
              }
              break
            }
            case 3: {
              this.islogin = false
              this.failed = true
              this.errorMessage = '当前登录账号手机号码与受邀请账号不一致'
              this.failedBtn = '退出登录'
              break
            }
            case 4: {
              this.islogin = false
              this.failed = true
              this.errorMessage = '当前登录账号邮箱与受邀请账号不一致'
              this.failedBtn = '退出登录'
              break
            }
            case 5: {
              this.islogin = false
              this.failed = true
              this.errorMessage = '您当前未登录，请登录该受邀账号后重试！'
              this.failedBtn = '登录'
              break
            }
          }
        })
        .catch(err => {
          this.spinning = false
          this.invitation.remark = '当前链接已失效'
          console.log(err)
        })
    },
    // 确认加入
    handleFinish () {
      // this.$refs.formRef.validate().then(() => {
      this.spinning = true
      this.state.loginBtn = true
      const obj = {
        shortKey: this.invitation.shortKey,
        // personId: userId,
        phone: this.formState.phone,
        checkCode: this.formState.checkCode,
        password: this.formState.password,
        userName: this.formState.name,
      }
      const loginObj = {
        userName: this.formState.phone,
        password: this.formState.password,
      }
      const successObj = {
        invitationEnterprise: this.invitation.invitationEnterprise,
      }
      if (!this.formState.name) {
        obj.userName = obj.phone
      }
      if (this.state.registToLogin) {
        this.state.loginBtn = true
        verifyLogin(this.formState.phone, this.formState.checkCode)
          .then(response => {
            if (response.data == null) {
              throw new Error(response)
            }
            if (response.status && response.status === 200) {
              this.$store.dispatch('account/setToken', response.data.rawData)
              this.$store.dispatch('account/setTenant', {
                tenantId: response.data.tenantId,
              })
              if (response.data.isBan) {
                this.state.loginBtn = false
                this.$message.error('您当前所在企业已被禁用,请切换其他企业')
                window.localStorage.clear()
                return
              }
              getProfileDetail().then(data => {
                this.$store.dispatch('profile/setProfile', data)
                this.data = data
                if (this.data.status === 1) {
                  this.state.loginBtn = false
                  this.spinning = false
                  this.$message.error('当前账户已被禁用，如有疑问请联系运维人员')
                  window.localStorage.clear()
                  return
                }
                const accData = {
                  shortKey: this.invitation.shortKey,
                }
                api
                  .post('/api/app/employee-invitation/accept-invitation', accData)
                  .then(({ data }) => {
                    this.spinning = false
                    this.state.loginBtn = false
                    this.$message.success('正在跳转')
                    this.$router.push({ path: '/account/Success', query: this.successObj })
                  })
                  .catch(err => {
                    this.spinning = false
                    this.state.loginBtn = false
                    // this.$message.error('操作失败')
                    console.log(err)
                  })
              })
            }
          })
          .catch(err => {
            // message.error(err.response.data.error.message)
            console.log(err)
          })
          .finally(() => { this.state.loginBtn = false })
        return
      }
      api
        .post('/api/app/employee-invitation/accept-invitation-regist-not-login', obj)
        .then(({ data }) => {
          this.spinning = false
          this.state.loginBtn = false
          this.$message.success('正在跳转')
          this.$router.push({ path: '/account/Success', query: this.successObj })
        })
        .catch(err => {
          this.spinning = false
          this.state.loginBtn = false
          // this.$message.error('操作失败')
          console.log(err)
        })
      // })
      // .catch(error => {
      //   console.log('error', error)
      // })
    },
    // 获取验证码
    getCaptcha (e) {
      if (!this.formState.phone) {
        this.$message.error('请先填写手机号')
        return
      }
      const obj = {
        phone: this.formState.phone,
        checkUser: true,
      }
      api
        .post('/api/app/account/send-verifycation-code', obj)
        .then(({ data }) => {
          console.log(data, 'captcha')
          this.countDown()
          if (data === true) {
            this.$notification.error({
              message: '手机号已存在',
              description:
                '当前手机号已有绑定账户，请输入验证码直接登录！',
            })
            this.state.registToLogin = true
          }
        })
        .catch(err => {
          this.spinning = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // 登录状态下的加入方法
    Acception () {
      this.state.loginBtn = true
      const obj = {
        id: null,
        shortKey: this.invitation.shortKey,
      }
      api
        .post('/api/app/employee-invitation/accept-invitation', obj)
        .then(({ data }) => {
          this.spinning = false
          this.state.loginBtn = false
          this.$message.success('正在跳转')
          this.$router.push({ path: '/account/success', query: this.successObj })
        })
        .catch(err => {
          this.spinning = false
          this.state.loginBtn = false
          // this.$message.error('操作失败')
          console.log(err)
        })
    },
    // 倒计时
    countDown () {
      const TIME_COUNT = 60
      this.countDownNum = TIME_COUNT
      this.startCountDown = false
      this.countDownTimer = setInterval(() => {
        if (this.countDownNum > 0 && this.countDownNum <= TIME_COUNT) {
          this.countDownNum--
        } else {
          this.startCountDown = true
          clearInterval(this.countDownTimer)
          this.countDownTimer = null
        }
      }, 1000)
    },
    // 返回登录页
    backLogin () {
      const item = this.invitation.result
      // console.log(item)
      if (item === 2) {
        this.$router.push({ path: '/WorkManagement/WorkBench' })
      }
      if (item === 5 || item === 0) {
        this.$router.push({ path: '/account/MobileLogin', query: this.$route.query })
      }
      if (item === 3 || item === 4) {
        this.handleLogout()
      }
    },
    // 协议按钮
    // protocolClick () {
    //   this.$refs.protocolView.viewShow()
    // },
    // 退出登录
    handleLogout () {
      this.state.loginBtn = true
      window.localStorage.clear()
      window.document.cookie = 'userName' + '=' + '' + ';path=/;expires='
      window.document.cookie = 'userPwd' + '=' + '' + ';path=/;expires='
      history.pushState(null, null, document.URL)
      window.addEventListener(
        'popstate',
        function (e) {
          history.pushState(null, null, document.URL)
        },
        false,
      )
      var url = window.location.href
      window.location.href = url
      // location.reload()
    },

  },
})
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.mobile-login {
  width: 100vw;
  height: 100vh;
  font-size: 1.5rem;
  background: rgb(233, 233, 233);
  .login {
    height: 90vh;
    padding: 5vh 5vw;
    border-radius: 2%;
    background-color: #fff;
  }
  // .user-layout-login {
  //   height: 80vh;
  // }
  .invitationPsn {
    max-width: 100%;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 3px solid #004ece;
  }
  .remark {
    width: 336px;
    font-size: 20px;
    // margin: 60px 0 60px;
    margin: 10px 0 20px;
    background: white;
    position: relative;
    font-family: "Microsoft YaHei";
    overflow: hidden;
    text-overflow: ellipsis;
    &:after {
      content: " ";
      width: 40px;
      height: 5px;
      background: #004ece;
      position: absolute;
      bottom: -10px;
      left: 0%;
    }
  }
  .islogin-remark {
    margin: 60px 0;
  }
  .failed-remark {
    margin: 60px 0 10px;
  }
  .failedInfo {
    margin: 30px 0 60px 0;
    font-size: 16px;
    color: gray;
    font-family: "Microsoft YaHei";
  }
  .logo {
    margin: 15px 0;
  }
  .title {
    margin: 0 10px;
    font-size: 20px;
    color: #004ece;
    font-weight: 700;
  }
  .getCaptcha {
    height: 30px;
    margin: 5px 0;
  }
  .islogin-button {
    width: 336px;
  }
  .countDownTimer {
    margin-top: 10px;
  }
}
.ant-spin {
  width: 25vw;
}
.invitation_enterprise {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
// .ant-spin-spinning {
//   margin-top: 256px;
// }
</style>
